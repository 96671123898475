import React, {useState, useRef} from 'react'
import "./donatebtn.css"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import clip from "../../assets/images/Copy.svg";

const DonateBtn = ({title}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isCopied, setIsCopied] = useState(false);
  const textRef = useRef(null);

  const handleCopyClick = () => {
    // Create a range object
    const range = document.createRange();

    // Select the text
    range.selectNode(textRef.current);

    // Create a selection object
    const selection = window.getSelection();

    // Remove existing selections
    selection.removeAllRanges();

    // Add the new range to the selection
    selection.addRange(range);

    // Copy the selected text
    document.execCommand('copy');

    // Clear the selection
    selection.removeAllRanges();

    setIsCopied(true);

    // Hide the message after a short delay
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);

  }

  const navigateToContact = () => {
    // Use the URL of the page you want to navigate to
    const newUrl = '/contact';
    
    if (window.location.pathname === newUrl) {
      // If the user is already on the target page, perform a full page reload
      window.location.reload();
    } else {
      // Navigate to the new page
      window.location.href = newUrl;
    }
  };  

  return (
    <div className='donate'>
        <button onClick={handleShow}>{title}</button>
        <Modal show={show} onHide={handleClose} centered>
  <Modal.Header closeButton>
  </Modal.Header>
  <Modal.Body>
    <div className="donatebtn-container">
      <div className="donatebtn-title"> 
        <p>Donate to us via bank transfer:</p>
      </div>
      <div className="donatebtn-acct"> 
      <div className="donatebtn-main">
        <h1 ref={textRef} >4091180433 </h1>
        <span onClick={handleCopyClick}> <img src={clip} alt="" /></span> 
      </div>
      {isCopied && <p className='copied'>Copied!</p>}
      </div>
      <div className="donatebtn-content"> 
      <p>Polaris Bank</p>
      <p>The Marbles</p>
      </div>
      <div className="donatebtn-title"> 
        <p>Thank you for choosing to be part of our story.</p>
      </div>
      <div className="donatebtn-close" onClick={navigateToContact}>
        <p>Please click here to donate through other means</p>
      </div>
    </div>
  
  </Modal.Body>
      </Modal>
    </div>
  )
}

export default DonateBtn