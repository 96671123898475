import React from 'react'
import "./joinusbtn.css"

const JoinUsBtn = () => {
  const navigateToContact = () => {
    // Use the URL of the page you want to navigate to
    const newUrl = '/contact';
    
    if (window.location.pathname === newUrl) {
      // If the user is already on the target page, perform a full page reload
      window.location.reload();
    } else {
      // Navigate to the new page
      window.location.href = newUrl;
    }
  };
  return (
        <div className='joinUs' onClick={navigateToContact}>
           <p style={{margin: "0"}} >Join Us</p>    
        </div>
  )
}

export default JoinUsBtn