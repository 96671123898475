import React, {useRef, useLayoutEffect, useState} from 'react'
import { gsap } from 'gsap'
import "./contact.css"
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import banner from "../../assets/images/Header.png"
import axios from 'axios';
import ScrollToTop from "react-scroll-to-top";

const Contact = () => {

  const bodyRef = useRef(null);

  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.from(
      bodyRef.current,
      {
        opacity: 0,
        y: 10, // Optional: Add a small vertical shift
        duration: 1.5,
        ease: "power1.out",
      }
    );
  
  }, []);

  const [errorMessage, setErrorMessage] = useState("");


  const [formData, setFormData] = useState ({
    name: "",
    email: "",
    subject: "",
    message: ""
  })

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.subject || !formData.message) {
      // You can display an error message, prevent the submission, or take any other action here
      setErrorMessage('Please fill in all fields!!');
      return;
  }

    try {
      const response = await axios.post('https://themarblesnigeria.org/sendemail.php', formData, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

        if (response.status === 200 ) {
            const data = response.data;
            console.log(data.message);
        } else {
            console.error('Request failed:', response.status, response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
    
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: ""
  });
  setErrorMessage("")
};



  return (
        <div id='contact'>
          <Navbar/>
          <ScrollToTop smooth height='15px' width='15px' />
        <div className='contact-container'ref={bodyRef} >
          <div className="contact-header-image" >
            <img src={banner} alt="contact-header" />
          </div>
          
          <div className="contact-form-container">
            <div className="contact-form">
              <h1>Get in touch<span style={{color:"#EA373C"}}>.</span></h1>
              <div className="contact-input-1">
                <input type='text' placeholder='Please Enter Your Name' name='name' value={formData.name} onChange={handleChange} />
                <input type='email' placeholder='Please Enter Your Email Address'  name='email' value={formData.email} onChange={handleChange} />
              </div>
              <div className="contact-input-2">
                <input type='text' placeholder='Message Subject' name='subject' value={formData.subject} onChange={handleChange} />
              </div>
              <div className="contact-input-3">
                <textarea type='text' placeholder='Type Message' name='message' value={formData.message} onChange={handleChange} />
              </div>
              <div className="contact-input-4">
                <button onClick={handleSubmit}>Send</button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        </div>
  )
}

export default Contact