import React, { useEffect } from 'react'
import "./homeabout.css"
import image1 from "../../assets/images/ab1.png"
import image2 from "../../assets/images/ab2.png"
import AOS from 'aos';
import 'aos/dist/aos.css';


const HomeAbout = () => {

  useEffect(()=>{
    AOS.init();
  },[])

  const navigateToAbout = () => {
    // Use the URL of the page you want to navigate to
    const newUrl = '/about';
    
    if (window.location.pathname === newUrl) {
      // If the user is already on the target page, perform a full page reload
      window.location.reload();
    } else {
      // Navigate to the new page
      window.location.href = newUrl;
    }
  };

  return (
    <div className='home-about'>
        <div className="home-about-container">
          <div className="home-about-title" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
            <h1>About Us</h1>
            <p>The Marbles Nigeria was born from a desire to spread awareness about mental health. In 2018, two friends, Abiola and Philip, embarked on a journey to create a more mentally healthy society. </p>
            <p>At The Marbles, we believe in the boundless potential of the next generation. Through our heartfelt events and initiatives, we're on a mission to make a positive impact on the lives of children and communities. Join us in creating a brighter, better future.</p>
            
            <div onClick={navigateToAbout} className='header-button about-button'>
            <p>Read More</p>
            </div>
          </div>
          <div className="home-about-image">
            <img src={image1} alt="about" className='home-about-image1' data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="2000"/>
            <img src={image2} alt="about" className='home-about-image2' data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="2300" />
          </div>
        </div>
    </div>
  )
}

export default HomeAbout