import React, { useEffect } from 'react'
import "./header.css"
import banner from "../../assets/images/head.png"
import AOS from 'aos';
import 'aos/dist/aos.css';


const Header = () => {

useEffect(()=>{
  AOS.init();
}, [])

  const navigateToAbout = () => {
    // Use the URL of the page you want to navigate to
    const newUrl = '/about';
    
    if (window.location.pathname === newUrl) {
      // If the user is already on the target page, perform a full page reload
      window.location.reload();
    } else {
      // Navigate to the new page
      window.location.href = newUrl;
    }
  };

  return (
    <div className='header' id='home'>
      <div className="header-image-container" >
        <div className="background-opacity"></div>
        <div className="header-image">
          <img src={banner} alt="" />
        </div>
        <div className="header-title" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
          <h1>Empowering the Future, One Child and Community at a Time.</h1>
          <div onClick={navigateToAbout} className='header-button'>
            <p>Read More</p>
            </div>
        </div>
      </div>
      

    </div>
  )
}

export default Header