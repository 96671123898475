import React, {useRef, useLayoutEffect} from 'react'
import "./home.css"
import Navbar from '../../components/navbar/Navbar'
import Header from '../../components/header/Header'
import HomeAbout from '../../components/homeAbout/HomeAbout'
import CTA from "../../components/CTA/CTA"
import Activities from '../../components/activities/Activities'
import Testimonials from '../../components/testimonials/Testimonials'
import logo from "../../assets/images/IconSVG.png"
import { gsap } from 'gsap'
import Footer from '../../components/footer/Footer'
import Inspire from '../../components/inspire/Inspire'
import ScrollToTop from "react-scroll-to-top";

const Home = () => {

  const screenRef = useRef(null);
  const bodyRef = useRef(null);
  const imageRef = useRef(null);


  useLayoutEffect(() => {
    const hasAlertBeenShown = localStorage.getItem('alertShown');

    // If the flag is not set, show the alert
    if (!hasAlertBeenShown) {
    const tl = gsap.timeline();
    tl.to(screenRef.current, {
      duration: 0.6,
      height: '100%',
      ease: 'power3.easeInOut',
    });
    tl.from(imageRef.current, {
      opacity: 0,
      duration: 0, 
    });
    tl.to(imageRef.current, {
      opacity: 1,
      duration: 0.3, 
    });
    tl.to(imageRef.current, {
      rotation: 360,
      duration: 1.5, 
    });
    tl.to(screenRef.current, {
      duration: 0.7,
      bottom: '100%',
      ease: 'power3.easeInOut',
    });
    tl.from(
      bodyRef.current,
      {
        opacity: 0,
        y: 10, // Optional: Add a small vertical shift
        duration: 0.5,
        ease: "power1.out",
      }
    );
    localStorage.setItem('alertShown', 'true');
  }
  }, []);
  return (
    <div>
           <div className="load-container">
          <div className="load-screen2" ref={screenRef}>
          <img
            style={{
              animation: `spin 5s linear infinite`,
              transform: `rotate(${Math.floor(Math.random() * 361)}deg)`,
            }}
            ref={imageRef}
            src={logo}
            alt="logo"
          />
          </div>
          
        </div> 
        <Navbar />
        <div ref={bodyRef} >
        <ScrollToTop smooth height='15px' width='15px' />
        <Header />
        <HomeAbout />
        <Activities />
        <Inspire />
        <CTA />
        <Testimonials />
        <Footer />
        </div>
    </div>
  )
}

export default Home