import React, {useState, useEffect} from 'react'
import "./testimonials.css"

import Slider from "react-slick";
import user from "../../assets/images/Photo.png"
import quote from "../../assets/images/Vector.png"
import AOS from 'aos';
import 'aos/dist/aos.css';

const testimonials = [
  {
    id: 1,
    name: 'Janet Doe',
    role: 'Volunteer',
    image: user, // Replace with the actual image URL
    quote: 'Designing dreams & illuminating futures! Freelance Graphics/Product Designer | Senior Festival Coordinator, Designing dreams & illuminating futures! Freelance.',
  },
  {
    id: 2,
    name: 'John Smith',
    role: 'Designer',
    image: user, // Replace with the actual image URL
    quote: 'Designing dreams & illuminating futures! Freelance Graphics/Product Designer | Senior Festival Coordinator, Designing dreams & illuminating futures! Freelance.',
  },
  {
    id: 3,
    name: 'John Smith',
    role: 'Designer',
    image: user, // Replace with the actual image URL
    quote: 'Designing dreams & illuminating futures! Freelance Graphics/Product Designer | Senior Festival Coordinator, Designing dreams & illuminating futures! Freelance.',
  },
  {
    id: 4,
    name: 'John Smith',
    role: 'Designer',
    image: user, // Replace with the actual image URL
    quote: 'Designing dreams & illuminating futures! Freelance Graphics/Product Designer | Senior Festival Coordinator, Designing dreams & illuminating futures! Freelance.',
  },
];


const Testimonials = () => {

  useEffect(()=>{
    AOS.init();
  },[])

  const [slidesToShow, setSlidesToShow] = useState(2);

  useEffect(() => {
  const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setSlidesToShow(2); // Large screen
      } 
      else {
        setSlidesToShow(1); // Small screen
      }
    };

    // Initial setup
    handleResize();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
      autoplaySpeed: 8000,
      pauseOnHover: true,
      cssEase: "linear"
  }
  return (
    <div className='testimonials'>
      <div className="testimonials-container">
        <div className="testimonials-header" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
          <h1>Testimonials</h1>
        </div>
        <Slider {...settings}>
      {testimonials.map((testimonial) => (
        <div key={testimonial.id}>
          <div className="testimonial-container" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
            <div className="div-slider-space">
              <div className="test-cards">
                <div className="testimonial-top">
                  <div className="testimonial-user">
                    <div className="testimonial-user-image">
                      <img src={testimonial.image} alt={testimonial.name} />
                    </div>
                    <div className="testimonial-user-details">
                      <h1>{testimonial.name}</h1>
                      <p>{testimonial.role}</p>
                    </div>
                  </div>
                  <div className="testimonial-quote">
                    <img src={quote} alt="Quote" />
                  </div>
                </div>
                <div className="testimonial-bottom">
                  <p>{testimonial.quote}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
        </div>
      </div>
  )
}

export default Testimonials