import React, {useRef, useLayoutEffect} from 'react'
import { gsap } from 'gsap'
import "./gallery.css"
import Accordion from 'react-bootstrap/Accordion';
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import banner from "../../assets/images/Header.svg"
import gallery from "../../assets/images/Photo8.png"
import ScrollToTop from "react-scroll-to-top";

const Gallery = () => {

  const bodyRef = useRef(null);

  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.from(
      bodyRef.current,
      {
        opacity: 0,
        y: 10, // Optional: Add a small vertical shift
        duration: 1.5,
        ease: "power1.out",
      }
    );
  
  }, []);

  return (
        <div id='contact'>
          <Navbar/>
          <ScrollToTop smooth height='15px' width='15px' />
          <div className="contact-container" ref={bodyRef}>
          <div className="contact-header-image" >
            <img src={banner} alt="contact-header" />
          </div>
            <div className="gallery-main">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="accordion-title">
                    <p className='accordion-p'>Community Projects</p>

                  </div>
                  
                  
                  </Accordion.Header>
                <Accordion.Body>
                <div className="about-gallery-image-container">
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div className="accordion-title">
                    <p className='accordion-p'>School Outreach Program</p>
                    
                  </div>
                  
                  
                  </Accordion.Header>
                <Accordion.Body>
                <div className="about-gallery-image-container">
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <div className="accordion-title">
                    <p className='accordion-p'>Conferences</p>
                  </div>
                  </Accordion.Header>
                <Accordion.Body>
                <div className="about-gallery-image-container">
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                  <div className="about-gallery-image">
                    <img src={gallery} alt="" />
                  </div>
                </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="gallery-accordion">

            </div>

          </div>
          </div>
          <Footer />
        </div>
  )
}

export default Gallery