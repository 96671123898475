import React, {useRef, useLayoutEffect, useEffect} from 'react'
import "./about.css"
import { gsap } from 'gsap'

import Navbar from '../../components/navbar/Navbar'
import Footer from "../..//components/footer/Footer"
import image1 from "../../assets/images/Picture.png"
import image2 from "../../assets/images/kid.png"
import image3 from "../../assets/images/kids.png"
import image4 from "../../assets/images/kids2.png"
import banner from "../../assets/images/about.png"
import gallery from "../../assets/images/Photo8.png"
import JoinUsBtn from '../../components/joinusButton/JoinUsBtn'

import AOS from 'aos';
import 'aos/dist/aos.css';

import ScrollToTop from "react-scroll-to-top";

const About = () => {

  const bodyRef = useRef(null);

  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.from(
      bodyRef.current,
      {
        opacity: 0,
        y: 10, // Optional: Add a small vertical shift
        duration: 1.5,
        ease: "power1.out",
      }
    );
  
  }, []);

  const navigateToGallery = () => {
    const newUrl = '/gallery';
    
    if (window.location.pathname === newUrl) {
      // If the user is already on the target page, perform a full page reload
      window.location.reload();
    } else {
      // Navigate to the new page
      window.location.href = newUrl;
    }
  }

  useEffect(()=>{
    AOS.init();
  },[])
  
  return (
       
        <div  id='about'>
          <Navbar />
          <ScrollToTop smooth height='15px' width='15px' />
        <div className='contact-container' ref={bodyRef}>
        <div className="contact-header-image" >
            <img src={banner} alt="contact-header" />
          </div>
          
        <div className="about-container">
       
          <div className="about-marbles">
            <div className="about-marbles-image">
              <img src={image1} alt="stuffs" />
            </div>
            <div className="about-marbles-content">
              <h1>We Are The Marbles</h1>
              <p>The Marbles Nigeria was born from a desire to spread awareness about mental health. In 2018, two friends, Abiola and Philip, embarked on a journey to create a more mentally healthy society. But they soon realized that mental health issues don't exist in isolation. Certain human and environmental factors play a crucial role. In 2020, they shifted their focus to a future where mental health issues are minimized by nurturing young minds. </p>  
              <p> </p>
              <JoinUsBtn />
            </div>
          </div>
          <div className="about-rational-container">
            <h1  data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="2000">Our Rationale <span style={{color:"#EA373C"}}>.</span> </h1>
            <div className="about-marbles rational-stuff">
            <div className="about-marbles-image"  data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="2000"> 
              <img src={image2} alt="stuffs" />
            </div>
            <div className="about-marbles-content"  data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
              <p>At The Marbles, we are dedicated to this vision. We provide public school students with educational materials and activities that show they are cared for. Our school outreach programs are designed to ensure every child has access to a quality education. We understand that healthy communities are essential, so we embark on projects that impact lives positively, making our neighbourhoods better places to live. </p>  
              <p>We also host conferences to propagate the essence of sustainable living, empowering the young generation with the knowledge and skills to shape a brighter, eco-conscious future. So far, we've touched 700 lives, impacted two schools, and  aligning with a community to uplift, which is the first of many. But we're just getting started.</p>
            </div>
          </div>
          </div>
          <div className="about-activities-container" id='activities'>
            <h1  data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="2000">Activites<span style={{color:"#EA373C"}}>.</span></h1>
            <div className="about-marbles ">
            <div className="about-marbles-image"  data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
              <img src={image3} alt="stuffs" />
            </div>
            <div className="about-marbles-content"  data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="2500">
              <h1>School Outreach Program</h1>
              <p> Our school outreach programs are designed to provide children with the tools they need to thrive. We give educational materials, support, and care to ensure every child can access a quality education. We also track the children's activities to ensure they are making progress. In addition, our school outreach programs aim to inspire a lifelong love for learning by creating a nurturing and engaging educational environment that fosters personal growth and development among children.</p>
            </div>
          </div>
          <div className="vertical"></div>
          <div className="about-marbles inverse">
            <div className="about-marbles-image"  data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
              <img src={image4} alt="stuffs" />
            </div>
            <div className="about-marbles-content"  data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="2500">
              <h1>Community Projects</h1>
              <p>Healthy communities serve as the cornerstone of thriving societies, and our projects are intricately designed to fortify this foundation. Our primary goal is to enhance living conditions while concurrently cultivating a profound sense of togetherness among community members. We recognize that the vitality and prosperity of a society are inexorably linked to the health and well-being of its constituent communities. Thus, our projects are meticulously crafted to address the multifaceted needs of these communities.</p>
            </div>
          </div>
          <div className="vertical"></div>
          <div className="about-marbles">
            <div className="about-marbles-image"  data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
              <img src={image1} alt="stuffs" />
            </div>
            <div className="about-marbles-content"  data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
              <h1>Conference</h1>
              <p>Our conferences stand as dynamic and transformative platforms that transcend traditional gatherings. These events are not just about knowledge sharing; they are immersive experiences designed to instill values and empower the next generation. At the heart of our conference philosophy is the belief that knowledge should be practical and actionable, and our diverse lineup of experts and thought leaders ensures precisely that. Their insights, experiences, and wisdom provide attendees with a rich tapestry of knowledge across various disciplines, encouraging multidisciplinary learning that is critical for tackling complex challenges.</p>
            </div>
          </div>
          </div>
        </div>
        <div className="about-gallery"  data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
          <h1>Gallery<span style={{color:"red"}}>.</span></h1>
          <div className="about-gallery-image-container">
            <div className="about-gallery-image">
              <img src={gallery} alt="" />
            </div>
            <div className="about-gallery-image">
              <img src={gallery} alt="" />
            </div>
            <div className="about-gallery-image">
              <img src={gallery} alt="" />
            </div>
            <div className="about-gallery-image">
              <img src={gallery} alt="" />
            </div>
            
          </div>
          <p onClick={navigateToGallery}>See more</p>
        </div>
        </div>
        <Footer />
        </div>
  )
}

export default About