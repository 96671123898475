import React, { useEffect } from 'react'
import "./activities.css"
import image2 from "../../assets/images/Picture.png"
import { HashLink } from 'react-router-hash-link'
import AOS from 'aos';
import 'aos/dist/aos.css';

const cardData = [
  {
    id: 0,
    img: image2,
    title: "School Outreach Program",
    content: "Our school outreach programs are designed to provide children with the tools they need to thrive. We give educational materials, support...",
    links: "Read More"
  },
  {
    id: 1,
    img: image2,
    title: "Community Projects",
    content: "Healthy communities are the backbone of a thriving society. Our projects focus on improving living conditions whilst fostering a sense of...",
    links: "Read More"
  },
  {
    id: 2,
    img: image2,
    title: "Conference",
    content: "Our conferences are a platform for imparting knowledge, instilling values, and empowering young minds. We bring experts and thought leaders...",
    links: "Read More"
  },
]

const Activities = () => {
  useEffect(()=>{
    AOS.init();
  },[])

  
  return (
    <div className='activities' id='activities'>
      <div className="act-container">
        <div className="act-title" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
          <h1>Activities</h1>
          <p>Our activities are meticulously designed to nurture the bright futures of the children we serve. Our commitment to empowering young minds and creating a positive impact is the driving force behind everything we do. </p>
        </div>
        <div className="act-cards-main">
          {
            cardData.map((item)=>{
              return(
                  <div key={item.id} className="act-card" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                    <img src={item.img} alt="card" />
                    <h1>{item.title}</h1>
                    <p>{item.content}</p>
                    <HashLink to="/about#activities">{item.links}</HashLink>
                </div>
              )
            })
          }
        </div>
        <div className="act-content">
          
        </div>
      </div>
    </div>
  )
}

export default Activities