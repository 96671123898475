import React, { useEffect } from 'react'
import image2 from "../../assets/images/Picture.png"
import "./inspire.css"
import JoinUsBtn from '../../components/joinusButton/JoinUsBtn'
import AOS from 'aos';
import 'aos/dist/aos.css';


const Inspire = () => {

  useEffect(()=>{
    AOS.init();
  },[])
  return (
    <div className='inspire'>
        <div className="about-marbles">
            <div className="about-marbles-image" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
              <img src={image2} alt="stuffs" />
            </div>
            <div className="about-marbles-content" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
              <h1>Inspired to be a part of the story?</h1>
              <p>Our Vision: "A World Where Hope Blossoms, One Child at a Time." </p>  
              <p>Our Mission: "To empower underprivileged children, nurture communities, and propagate sustainable living for a brighter tomorrow."</p>
            <JoinUsBtn />
            </div>
          </div>
    </div>
  )
}

export default Inspire