import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import './App.css';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Gallery from './pages/gallery/Gallery';

function App() {
  useEffect(() => {
    // Check if the flag is set in localStorage
    const hasAlertBeenShown = localStorage.getItem('alertShown');

    // If the flag is not set, show the alert
    if (!hasAlertBeenShown) {
      alert('This is the alert message.');

      // Set the flag in localStorage to indicate that the alert has been shown
      localStorage.setItem('alertShown', 'true');
    }
  }, []); // The empty dependency array ensures that this effect runs only once on mount

  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path='gallery' element={<Gallery />} />
          <Route path="contact" element={<Contact />} />
        </Routes>
      </Router>
  );
}

export default App;
