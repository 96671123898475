import React, {useState, useRef}from 'react'
import logo from "../../assets/images/Logo.svg"
import clip from "../../assets/images/Copy.svg"
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { AiOutlineInstagram, AiOutlineTwitter} from "react-icons/ai";
import { BiLogoGmail } from "react-icons/bi";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import banner from "../../assets/images/Marbles.png"

import "./footer.css"

const Footer = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const date = new Date();

  const navigateToHome = () => {
    // Use the URL of the page you want to navigate to
    const newUrl = '/';
    
    if (window.location.pathname === newUrl) {
      // If the user is already on the target page, perform a full page reload
      window.location.reload();
    } else {
      // Navigate to the new page
      window.location.href = newUrl;
    }
  }; 
  const navigateToAbout = () => {
    // Use the URL of the page you want to navigate to
    const newUrl = '/about';
    
    if (window.location.pathname === newUrl) {
      // If the user is already on the target page, perform a full page reload
      window.location.reload();
    } else {
      // Navigate to the new page
      window.location.href = newUrl;
    }
  };
  const navigateToGallery = () => {
    const newUrl = '/gallery';
    
    if (window.location.pathname === newUrl) {
      // If the user is already on the target page, perform a full page reload
      window.location.reload();
    } else {
      // Navigate to the new page
      window.location.href = newUrl;
    }
  }
  const navigateToContact = () => {
    // Use the URL of the page you want to navigate to
    const newUrl = '/contact';
    
    if (window.location.pathname === newUrl) {
      // If the user is already on the target page, perform a full page reload
      window.location.reload();
    } else {
      // Navigate to the new page
      window.location.href = newUrl;
    }
  };

  const [isCopied, setIsCopied] = useState(false);
  const textRef = useRef(null);

  const handleCopyClick = () => {
    // Create a range object
    const range = document.createRange();

    // Select the text
    range.selectNode(textRef.current);

    // Create a selection object
    const selection = window.getSelection();

    // Remove existing selections
    selection.removeAllRanges();

    // Add the new range to the selection
    selection.addRange(range);

    // Copy the selected text
    document.execCommand('copy');

    // Clear the selection
    selection.removeAllRanges();

    setIsCopied(true);

    // Hide the message after a short delay
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);

  }
  return (
    <div className='footer'> 
      <div className="footer-container">
      
        <div className="footer-top-container">
        <div className="footer-logo" onClick={navigateToHome}>
              <img src={logo} alt="logo" />
            </div>
          <div className="footer-top">
          <div className="footer-top-1">
            
            <p>At The Marbles, we believe in the boundless <br /> potential of the next generation.</p>
            <div className="footer-top-1-contact">
              <div className="footer-contact1">
                <div className="footer-contact-image">
                  <FaLocationDot />
                </div>
                <p>Lagos, Nigeria</p>
              </div>
              <div className="footer-contact1" style={{alignItems:"flex-start"}}>
                <div className="footer-contact-image " style={{backgroundColor: "black", marginTop:"0.4rem"}}>
                  <BsFillTelephoneFill /> 
                  </div>
                <p>+234 815 778 1081  <br/> +234 818 211 2050</p>
              </div>
            </div>
            <div className="footer-socials">
            <div className="footer-social-image1">
              <AiOutlineInstagram />
            </div>
            <div className="footer-social-image2">
              <AiOutlineTwitter />
            </div>
            <div className="footer-social-image3">
            <BiLogoGmail />
            </div>
          </div>
          </div>
          <div className="footer-top-2">
            <h1>Quick links</h1>
          <ul>
         
              <li>
                <span onClick={navigateToAbout}>About</span>
              </li>
              <li>
                <span onClick={navigateToHome}>Volunteer</span>
              </li>
             
              <li>
                <span onClick={handleShow}>Donate</span>
              </li>
              <li>
                <span onClick={navigateToGallery}>Gallery</span>
              </li>
              <li>
                <span onClick={navigateToContact}>Contact</span>
              </li>
              <Modal show={show} onHide={handleClose} centered>
  {/* <Modal.Header closeButton>
  </Modal.Header> */}
  <Modal.Body>
    <div className="donatebtn-container">
      <div className="donatebtn-title"> 
        <p>Donate to us via bank transfer:</p>
      </div>
      <div className="donatebtn-acct"> 
      <div className="donatebtn-main">
        <h1 ref={textRef} >4091180433 </h1>
        <span onClick={handleCopyClick}> <img src={clip} alt="" /></span> 
      </div>
      {isCopied && <p className='copied'>Copied!</p>}
      </div>
      <div className="donatebtn-content"> 
      <p>Polaris Bank</p>
      <p>The Marbles</p>
      </div>
      <div className="donatebtn-title"> 
        <p>Thank you for choosing to be part of our story.</p>
      </div>
      <div className="donatebtn-close" onClick={navigateToContact}>
        <p>Please click here to donate through other means</p>
      </div>
    </div>
  
  </Modal.Body>
</Modal>
                </ul>
          </div>
          <div className="footer-top-3">
              <h1>Get Update From Us </h1>
              <p>Subscribe to our newsletter to get updates about our activities and events.</p>
              <div className='footer-top-3-input'>
                <input type='text' placeholder='Enter Your Email Here' />
                <button>Subscribe Now</button>
              </div>
          </div>
          </div>
        
        </div>
        <div className="footer-bottom">
          <div className="footer-copyright">
            <p>&#169; {date.getFullYear()} The Marbles. All rights reserved.</p>
          </div>
          
        </div>
      </div>
      <div className="footer-background">
          <img src={banner} alt="" />
        </div>
    </div>
  )
}

export default Footer