import React, { useEffect } from 'react'
import "./cta.css"
import banner from "../../assets/images/Donate-Activity.png"
import DonateBtn from '../donateButton/DonateBtn'
import AOS from 'aos';
import 'aos/dist/aos.css';


const CTA = () => {

  useEffect(()=>{
    AOS.init()
  },[])

  return (
    <div className='cta'>
      <div className="cta-container">
        <div className="cta-image">
          <img src={banner} alt="" />
        </div>
        <div className="cta-title" data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
          <h1>We Can Do More Together.</h1>
          <p>Join us in creating a world where hope blooms, one child, one community, and one conference at a time. Your support can make all the difference.</p>
          <DonateBtn title="Donate To An Activity" />
        </div>
      </div>
    </div>
  )
}

export default CTA