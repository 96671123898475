import React, {useState, useEffect} from 'react'
import { HashLink } from 'react-router-hash-link'
import logo from '../../assets/images/Logo.svg'
import {RiCloseLine, RiMenu3Line} from "react-icons/ri"
import {AiOutlineTwitter, AiFillFacebook, AiOutlineInstagram, } from "react-icons/ai"
import { Link } from 'react-router-dom'



import "./navbar.css"
import DonateBtn from '../donateButton/DonateBtn'

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);

    useEffect(() => {
        if (toggleMenu) {
          document.body.classList.add('no-scroll');
        } else {
          document.body.classList.remove('no-scroll');
        }
    
        // Cleanup: Remove the class when the component unmounts
        return () => {
          document.body.classList.remove('no-scroll');
        };
      }, [toggleMenu]);


      const navigateToHome = () => {
        // Use the URL of the page you want to navigate to
        const newUrl = '/';
        
        if (window.location.pathname === newUrl) {
          // If the user is already on the target page, perform a full page reload
          window.location.reload();
        } else {
          // Navigate to the new page
          window.location.href = newUrl;
        }
      };
      const navigateToAbout = () => {
        // Use the URL of the page you want to navigate to
        const newUrl = '/about';
        
        if (window.location.pathname === newUrl) {
          // If the user is already on the target page, perform a full page reload
          window.location.reload();
        } else {
          // Navigate to the new page
          window.location.href = newUrl;
        }
      };
      const navigateToGallery = () => {
        const newUrl = '/gallery';
        
        if (window.location.pathname === newUrl) {
          // If the user is already on the target page, perform a full page reload
          window.location.reload();
        } else {
          // Navigate to the new page
          window.location.href = newUrl;
        }
      }
      const navigateToContact = () => {
        // Use the URL of the page you want to navigate to
        const newUrl = '/contact';
        
        if (window.location.pathname === newUrl) {
          // If the user is already on the target page, perform a full page reload
          window.location.reload();
        } else {
          // Navigate to the new page
          window.location.href = newUrl;
        }
      };

  return (
    <div className='navbar'>
        <div className="navbar-container">
            <div className="navbar-logo" onClick={navigateToHome}>
                <img src={logo} alt="" />
            </div>
            <div className="navbar-content">
                <ul>
                    <li>
                    <span onClick={navigateToHome}>Home</span>
                    </li>
                    <li>
                        <span onClick={navigateToAbout}>About</span>
                    </li>
                    <li>
                        <span onClick={navigateToContact}>Contact</span>
                    </li>
                    <li>
                      <span onClick={navigateToGallery}>Gallery</span>
                    </li>
                </ul>
                <DonateBtn title="Donate" />
            </div>
            <div className="navbar-mini-container">
                <div style={{marginRight:"0.7rem"}}><DonateBtn title="Donate" /></div>

                {
                toggleMenu ?
                <RiCloseLine color='black' size={28} onClick={()=>setToggleMenu(false)}/>
                : 
                <RiMenu3Line color='black' size={28} onClick={()=>setToggleMenu(true)}/>
                }
                {   toggleMenu && (
                <div className= {`navbar-menu_container ${ toggleMenu ? "slide-left" : "slide-top"}`}>
                    <div className="navbar-menu_links">
                        <nav >
                          <ul>
                          <li>
                            <span onClick={navigateToHome}>Home</span>
                            </li>
                            <li>
                                <span onClick={navigateToAbout}>About</span>
                            </li>
                            <li>
                                <span onClick={navigateToContact}>Contact</span>
                            </li>
                            <li>
                                <span onClick={navigateToGallery}>Gallery</span>
                            </li>
                   
                            <div className='navbar-socials'>
                                <Link to='/'><AiOutlineTwitter  className='social-logo'/></Link>
                                <Link to='/'><AiFillFacebook  className='social-logo'/></Link>
                                <Link to='/'><AiOutlineInstagram  className='social-logo'/></Link>
                            </div>
                          </ul>
                        </nav>
                      </div>
                    </div>
            )
        }

                </div>
        </div>
    </div>
  )
}

export default Navbar